import React from "react";
import { type TagListData } from "../../../types/games";

interface TagListProps {
  data: TagListData;
}

const TagList: React.FC<TagListProps> = ({ data }) => {
  if (!data || !data.length) return null;

  return (
    <div className="flex items-center flex-wrap gap-2">
      {data.map((item, idx) => (
        <div
          key={`${item.title}-${item?.text ?? ""}-${idx}`}
          className="flex items-center gap-2 py-2 px-4 font-sans text-brand leading-4 text-sm tracking-tight bg-brand/[0.08] rounded-[32px]"
        >
          <div className="font-bold">{item.title}</div>
          {item.text && <div className="font-normal">{item.text}</div>}
        </div>
      ))}
    </div>
  );
};

export default TagList;
