import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  ResponsiveContainer,
  LabelProps,
} from "recharts";
import {
  type HorizontalBarChartData,
  type HorizontalBarItem,
} from "../../../types/games";

type HorizontalBarProps = {
  data: HorizontalBarChartData;
};

const SYMBOL_MULTIPLIER = 7.5;
const ROW_MULTIPLIER = 39;
const MAX_LABEL_LENGTH = 84;

const prepareLabels = (data: HorizontalBarItem[]) =>
  data.map((item) => {
    let labelValue: string[] = [item.name];

    if (item.name.length * SYMBOL_MULTIPLIER > MAX_LABEL_LENGTH) {
      let cur = "";
      labelValue = [];

      for (const substring of item.name.split(" ")) {
        const concatenatedWord = `${cur} ${substring}`;

        if (concatenatedWord.length * SYMBOL_MULTIPLIER < MAX_LABEL_LENGTH) {
          cur = concatenatedWord;
        } else if (substring.length * SYMBOL_MULTIPLIER > MAX_LABEL_LENGTH) {
          labelValue.push(cur, substring);
          cur = "";
        } else {
          labelValue.push(cur);
          cur = substring;
        }
      }

      if (cur) labelValue.push(cur);
    }

    return {
      initial: item.name,
      parts: labelValue,
    };
  });

const HorizontalBarChart: React.FC<HorizontalBarProps> = ({ data }) => {
  if (!data || !data.length) return null;

  const maxValue = Math.max(...data.map((item) => item.value));
  const preparedLabels = prepareLabels(data);

  const maxYAxisNameLength = preparedLabels.reduce((max, el) => {
    const longestPartLength = Math.max(...el.parts.map((part) => part.length));
    return longestPartLength > max ? longestPartLength : max;
  }, 0);

  const YAxisWidth = Math.min(
    maxYAxisNameLength * SYMBOL_MULTIPLIER,
    MAX_LABEL_LENGTH,
  );
  const chartHeight = data.length * ROW_MULTIPLIER;

  return (
    <ResponsiveContainer width="100%" height={chartHeight}>
      <BarChart
        data={data}
        layout="vertical"
        compact
        margin={{ top: -4, left: 8 }}
      >
        <XAxis
          type="number"
          width={0}
          tickLine={false}
          tickSize={0}
          tick={<></>}
          axisLine={false}
          display={"none"}
          hide
        />
        <YAxis
          type="category"
          dataKey="name"
          tickLine={false}
          axisLine={false}
          width={YAxisWidth}
          tick={({ y, payload }) => {
            const labelValue = preparedLabels[payload.index]?.parts;

            const initialY = labelValue.length > 1 ? -4 : 2;

            return (
              <g transform={`translate(${0},${y + 2})`}>
                <text
                  x={0}
                  y={2}
                  fill="#000000"
                  textAnchor="start"
                  fontSize={12}
                  fontFamily="Inter"
                  fontWeight={600}
                  width={30}
                >
                  {labelValue.map((word: string, index: number) => {
                    const substringY = initialY + index * 12;
                    return (
                      <tspan key={word} x={0} y={substringY}>
                        {word}
                      </tspan>
                    );
                  })}
                </text>
              </g>
            );
          }}
        />
        <defs>
          <linearGradient id="gradientBg" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#7367FF" />
            <stop offset="100%" stopColor="#4641EB" />
          </linearGradient>
        </defs>
        <Bar
          dataKey="value"
          barSize={32}
          radius={[4, 32, 32, 4]}
          label={({ x, y, width, height, value, offset }: LabelProps) => {
            const widthNum = +(width ?? 0);
            const heightNum = +(height ?? 0);
            const offsetNum = +(offset ?? 0);
            const xNum = +(x ?? 1);
            const yNam = +(y ?? 0);
            const realOffset = xNum - offsetNum - 12;

            return (
              <text
                x={widthNum + realOffset}
                y={heightNum / 2 + yNam + offsetNum}
                fill={value === maxValue ? "#fff" : "#3C2B87"}
                textAnchor="middle"
                fontSize={14}
                fontFamily="Inter"
                fontWeight={600}
              >
                {value}
              </text>
            );
          }}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}-${entry.value}`}
              fill={entry.value === maxValue ? "url(#gradientBg)" : "#EFEEF5"}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HorizontalBarChart;
