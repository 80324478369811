import { Link, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faUser } from "@fortawesome/free-solid-svg-icons";

import Logo from "../assets/braid-teams-logo.png";

// Helper to create links that preserve the team parameter
const createTeamLink = (path: string, teamId: string | null) => {
  return teamId ? `${path}?team=${teamId}` : path;
};

const navItems = [
  { to: "/profile", icon: faUser, text: "Profile" },
  { to: "/settings", icon: faCog, text: "Settings" },
];

const isActivePath = (path: string, pathname: string): boolean => {
  return pathname.startsWith(path);
};

function Header() {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const teamId = searchParams.get("team");

  return (
    <header className="bg-white shadow">
      <div className="container max-w-5xl mx-auto py-4 flex justify-between items-center px-2 lg:px-0 md:px-0">
        <div className="flex items-center">
          <img src={Logo} alt="Braid Teams Logo" className="h-8 w-auto mr-2" />
        </div>
        <nav className="font-dmSans font-bold">
          {navItems.map(({ to, icon, text }) => {
            const isActive = isActivePath(to, pathname);
            return (
              <Link
                key={to}
                to={createTeamLink(to, teamId)}
                className={`
                  px-3 py-2 rounded-md text-sm md:text-md lg:text-md inline-flex mx-1
                  ${
                    isActive
                      ? "bg-brand/10 text-brandCompliment"
                      : "text-brand hover:bg-brand hover:bg-opacity-10"
                  }
                `}
              >
                <div className="flex items-center">
                  <FontAwesomeIcon className="pr-2" icon={icon} size="lg" />
                  {text}
                </div>
              </Link>
            );
          })}
        </nav>
      </div>
    </header>
  );
}

export default Header;
