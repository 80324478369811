import React from "react";
import { type SquaresGridData } from "../../../types/games";

interface SquaresGridProps {
  maxColumns?: 2 | 4;
  size?: "large" | "default" | "small";
  data: SquaresGridData;
}

const SquaresGrid: React.FC<SquaresGridProps> = ({
  data,
  maxColumns = 2,
  size = "default",
}) => {
  const wrapperClass =
    maxColumns === 4 ? "sm:grid-cols-2 md:grid-cols-4" : "sm:grid-cols-2";

  const minHeightMap = {
    small: "72x",
    default: "88px",
    large: "128px",
  };

  const lastItemIndex = data.length - 1;
  const lastSquareColClass = data.length % 2 ? "col-span-2" : "";

  if (!data || !data.length) return null;

  return (
    <div className={`grid gap-4 ${wrapperClass}`}>
      {data.map((item, index) => (
        <div
          className={`grid gap-1 content-center text-center p-2 font-sans min-h-[${minHeightMap[size]}] text-brand tracking-tight bg-brand/[0.08] rounded-lg ${index === lastItemIndex ? lastSquareColClass : ""}`}
          key={item.title + item.text}
        >
          <div className="font-bold text-2xl">{item.title}</div>
          <div className="font-medium text-sm ">{item.text}</div>
        </div>
      ))}
    </div>
  );
};

export default SquaresGrid;
