import { useAuth } from "@clerk/clerk-react";
import { useApi } from "../../hooks/useApi";
import { buildApiUrl } from "../../api";
import { toast } from "react-toastify";
import Card from "../card";

import * as Sentry from "@sentry/react";

interface AccountSectionProps {
  teamId: string | null;
}

const AccountSection = ({ teamId }: AccountSectionProps) => {
  const { signOut } = useAuth();
  const api = useApi();

  const handleSignOut = async () => {
    try {
      await signOut({
        redirectUrl: "/profile?team=" + teamId,
      });
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to sign out", {
        position: "bottom-center",
      });
    }
  };

  const disconnectSlack = async () => {
    try {
      await api.post(buildApiUrl("/v2/slack/disconnect", teamId));
      window.location.href = "/";
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Failed to disconnect Slack", {
        position: "bottom-center",
      });
    }
  };

  return (
    <Card>
      <div className="space-y-6">
        <div>
          <h2 className="text-xl font-semibold mb-4">Account Management</h2>
          <p className="text-sm text-slate-500 mt-1">
            Manage your account access and connections
          </p>
        </div>

        <div className="flex gap-x-4 justify-between">
          <button
            onClick={disconnectSlack}
            className="px-4 py-2 rounded-lg border hover:border-brand hover:text-brand transition-colors duration-200 font-medium outline-red border-brandRed text-brandRed/90"
          >
            Disconnect Slack
          </button>

          <button
            onClick={handleSignOut}
            className="px-4 py-2 rounded-lg text-slate-700 transition-all duration-200 border font-medium border-gray-800 hover:border-brand hover:text-brand"
          >
            Sign Out
          </button>
        </div>
      </div>
    </Card>
  );
};

export default AccountSection;
