import { Loader2 } from "lucide-react";
import { ButtonHTMLAttributes, forwardRef } from "react";

type ButtonVariant = "primary" | "secondary" | "outline";
type ButtonSize = "sm" | "md" | "lg";

interface CtaButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  loadingText?: string;
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
}

const CtaButton = forwardRef<HTMLButtonElement, CtaButtonProps>(
  (
    {
      children,
      className = "",
      disabled,
      isLoading = false,
      loadingText,
      variant = "primary",
      size = "md",
      fullWidth = false,
      ...props
    },
    ref,
  ) => {
    const baseStyles =
      "rounded-xl font-medium transition-all duration-200 inline-flex items-center justify-center";

    const variants = {
      primary:
        "bg-gradient-cta hover:bg-gradient-cta-hover text-white shadow-cta hover:shadow-cta-hover",
      secondary:
        "bg-white hover:bg-gray-50 text-brand border-2 border-brand/10 shadow-sm",
      outline:
        "bg-transparent hover:bg-gray-50 text-gray-700 border-2 border-gray-200",
    };

    const sizes = {
      sm: "px-4 py-2 text-sm",
      md: "px-6 py-3",
      lg: "px-8 py-4 text-lg",
    };

    const width = fullWidth ? "w-full" : "w-auto";

    const isDisabled = disabled || isLoading;

    return (
      <button
        ref={ref}
        disabled={isDisabled}
        className={`
          ${baseStyles}
          ${variants[variant]}
          ${sizes[size]}
          ${width}
          ${isDisabled ? "opacity-50 cursor-not-allowed shadow-none" : "transform hover:-translate-y-0.5 active:translate-y-0"}
          ${className}
        `}
        {...props}
      >
        {isLoading ? (
          <span className="flex items-center justify-center">
            <Loader2
              size={size === "sm" ? 16 : 20}
              className="animate-spin mr-2"
            />
            {loadingText}
          </span>
        ) : (
          children
        )}
      </button>
    );
  },
);

CtaButton.displayName = "CtaButton";

export default CtaButton;
