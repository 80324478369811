import React from "react";
import {
  WidgetCard,
  HorizontalBar,
  SquaresGrid,
  TagList,
  Testimonials,
  GradientLineChart,
  KeyValueList,
} from "./insights";

import {
  GameName,
  GameType,
  GameInsightsMap,
  Testimonial,
  Square,
  Tag,
  HorizontalBarItem,
  GradientLineChartData,
  KeyValueItem,
} from "../types/games";

import {
  THIS_OR_THAT,
  WORDSMITH,
  PICTURE_PUZZLE,
  TYPING_TEST,
  WORD_LINK,
} from "../constants";

interface GameInsightsResponse {
  game_type: GameName;
  insights?: GameInsightsMap[GameName];
}

interface GameInsightProps {
  data: GameInsightsResponse;
  game: GameType;
}

interface TestimonialsProps {
  data: Testimonial[];
}

interface SquaresGridProps {
  data: Square[];
  size?: "small" | "large";
  maxColumns?: number;
}

interface HorizontalBarProps {
  data: HorizontalBarItem[];
}

interface TagListProps {
  data: Tag[];
}

interface KeyValueListProps {
  data: KeyValueItem[];
}

interface GradientLineChartProps {
  data: GradientLineChartData;
}

type InsightComponents = {
  Testimonials: {
    component: typeof Testimonials;
    props: Omit<TestimonialsProps, "data">;
    dataType: Testimonial[];
  };
  SquaresGrid: {
    component: typeof SquaresGrid;
    props: Omit<SquaresGridProps, "data">;
    dataType: Square[];
  };
  HorizontalBar: {
    component: typeof HorizontalBar;
    props: Omit<HorizontalBarProps, "data">;
    dataType: HorizontalBarItem[];
  };
  TagList: {
    component: typeof TagList;
    props: Omit<TagListProps, "data">;
    dataType: Tag[];
  };
  KeyValueList: {
    component: typeof KeyValueList;
    props: Omit<KeyValueListProps, "data">;
    dataType: KeyValueItem[];
  };
  GradientLineChart: {
    component: typeof GradientLineChart;
    props: Omit<GradientLineChartProps, "data">;
    dataType: GradientLineChartData;
  };
};

interface InsightConfig {
  id: keyof GameInsightsMap[keyof GameInsightsMap] | string;
  title: string;
  subtitle: string;
  halfWidth?: boolean;
  children: {
    component: InsightComponents[keyof InsightComponents]["component"];
    props: Partial<InsightComponents[keyof InsightComponents]["props"]>;
  }[];
}

type GameInsightConfigs = {
  [K in keyof GameInsightsMap]: InsightConfig[];
};

const gameInsightConfigs: GameInsightConfigs = {
  [THIS_OR_THAT]: [
    {
      id: "data",
      title: "Teammates you have the most in common with",
      subtitle: "How often you pick the same preference for a question",
      halfWidth: false,
      children: [
        {
          component: Testimonials,
          props: {},
        },
      ],
    },
  ],
  [WORD_LINK]: [
    {
      id: "word_link_num_unique_words",
      title: "Number of unique words",
      subtitle: "How many words you've used in all games",
      halfWidth: true,
      children: [
        {
          component: SquaresGrid,
          props: { size: "small" },
        },
      ],
    },
    {
      id: "word_link_performance",
      title: "Unique words compared to team",
      subtitle: "How you stack up against your teammates",
      halfWidth: true,
      children: [
        {
          component: HorizontalBar,
          props: {},
        },
      ],
    },
    {
      id: "word_link_common_words",
      title: "Most frequently played words",
      subtitle: "Words that you use the most often",
      halfWidth: true,
      children: [{ component: TagList, props: {} }],
    },
    {
      id: "word_link_strategies",
      title: "Your top strategies",
      subtitle: "Words that you use the most often",
      halfWidth: true,
      children: [
        {
          component: KeyValueList,
          props: {},
        },
      ],
    },
  ],
  [WORDSMITH]: [
    {
      id: "wordsmith_level_distribution",
      title: "Number of games at each smith level",
      subtitle: "How many times do you reach a specific level",
      halfWidth: true,
      children: [
        {
          component: HorizontalBar,
          props: {},
        },
      ],
    },
    {
      id: "wordsmith_performance",
      title: "Overall performance",
      subtitle: "How you performed across all your games",
      halfWidth: true,
      children: [{ component: SquaresGrid, props: { size: "large" } }],
    },
    {
      id: "wordsmith_longest_words",
      title: "Longest words played",
      subtitle: "Words that you've played ranked by length",
      halfWidth: false,
      children: [{ component: TagList, props: {} }],
    },
  ],
  [PICTURE_PUZZLE]: [
    {
      id: "picture_puzzle_guess_distribution",
      title: "Number of attempts to complete",
      subtitle: "How many attempts it took you to find the answer",
      halfWidth: true,
      children: [{ component: HorizontalBar, props: {} }],
    },
    {
      id: "picture_puzzle_performance",
      title: "Overall performance",
      subtitle: "How you performed across all games",
      halfWidth: true,
      children: [{ component: SquaresGrid, props: {} }],
    },
  ],
  [TYPING_TEST]: [
    {
      id: "typing_test_performance",
      title: "Overall performance",
      subtitle: "How you performed across all tests",
      halfWidth: false,
      children: [{ component: SquaresGrid, props: { maxColumns: 4 } }],
    },
    {
      id: "typing_test_wpm_trend",
      title: "WPM over time",
      subtitle: "How your WPM has changed",
      halfWidth: true,
      children: [{ component: GradientLineChart, props: {} }],
    },
    {
      id: "typing_test_accuracy_trend",
      title: "Accuracy time",
      subtitle: "How your accuracy has changed",
      halfWidth: true,
      children: [{ component: GradientLineChart, props: {} }],
    },
  ],
};

const GameInsight: React.FC<GameInsightProps> = ({ data, game }) => {
  const config = gameInsightConfigs[game.name as keyof GameInsightsMap];
  if (!config || !data.insights) return null;

  const renderInsightContent = (widget: InsightConfig) => {
    const insights = data.insights as GameInsightsMap[keyof GameInsightsMap];
    if (!insights) return null;

    return widget.children.map((child, idx) => {
      const ChildComponent = child.component;
      const insightKey = widget.id as keyof typeof insights;
      const insightData = insights[insightKey];

      if (!insightData) return null;

      const hasEnoughData = Array.isArray(insightData)
        ? insightData.length > 0
        : typeof insightData === "object" &&
          Object.keys(insightData).length > 0;

      if (!hasEnoughData) {
        return (
          <div
            className="h-[180px] flex items-center justify-center text-sm text-brandRed"
            key={`${idx}-not-enough-data`}
          >
            📈 Not enough data to show insights
          </div>
        );
      }

      return (
        <ChildComponent
          key={`${String(widget.id)}-${idx}`}
          // @ts-expect-error this is already safe
          data={insightData}
          {...child.props}
        />
      );
    });
  };

  return (
    <div className="max-w-full w-[64rem] px-0 md:px-[18px] mx-auto grid gap-6 grid-col-1">
      {config.map((widget) => (
        <div key={widget.id} className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <WidgetCard title={widget.title} subtitle={widget.subtitle}>
            {renderInsightContent(widget)}
          </WidgetCard>
        </div>
      ))}
    </div>
  );
};

export default GameInsight;
