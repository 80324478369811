import { useNavigate } from "react-router-dom";
import blurBg from "../assets/blur.png";
import upgradeOverlay from "../assets/upgrade.png";

const UpgradeSection = () => {
  const navigate = useNavigate();

  return (
    <div className="relative mb-4">
      <img
        src={blurBg}
        alt="Insights preview"
        className="w-full h-[500px] object-cover rounded-lg blur-sm"
      />
      {/* Content overlay */}
      <div className="absolute inset-0 flex items-center justify-center">
        <img
          src={upgradeOverlay}
          alt="Upgrade to Premium"
          className="max-w-[600px] w-full cursor-pointer hover:opacity-90 transition-opacity"
          onClick={() => navigate("/settings")}
        />
      </div>
    </div>
  );
};

export default UpgradeSection;
