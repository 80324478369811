import React from "react";
import { type KeyValueData } from "../../../types/games";

interface KeyValueListProps {
  data: KeyValueData;
}

const KeyValueList: React.FC<KeyValueListProps> = ({ data }) => (
  <div className="grid gap-2">
    {data.map((item) => (
      <div
        key={`${item.key}-${item?.value ?? ""}`}
        className="flex justify-between gap-2 py-2 px-3 font-sans text-brand leading-4 text-xs tracking-tight bg-brand/[0.08] rounded"
      >
        <div className="font-medium">{item.key}</div>
        {item.value && <div className="font-normal">{item.value}</div>}
      </div>
    ))}
  </div>
);

export default KeyValueList;
