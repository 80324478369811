import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useApi } from "../hooks/useApi";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { API_BASE_URL, MINIMUM_PLAYS_FOR_INSIGHTS } from "../constants";
import { SlackUser } from "../types/user";
import LoadingSpinner from "./spinner";
import GameInsight from "./gameInsights";
import { generateSlackDeepLink } from "../lib/utils";

import { GameName, GameType, GameInsightsMap } from "../types/games";

interface DataResponse {
  game_type: GameName;
  last_played?: string;
  play_count: number;
  insights?: GameInsightsMap[GameName];
}

interface ProgressStepsProps {
  total: number;
  current: number;
}

// Separate loading states enum for better state management
const LoadingState = {
  INITIAL: "initial",
  LOADING: "loading",
  ERROR: "error",
  SUCCESS: "success",
} as const;

type LoadingStateType = (typeof LoadingState)[keyof typeof LoadingState];

const ProgressSteps = ({ total, current }: ProgressStepsProps) => {
  return (
    <div className="flex items-center gap-2 ">
      {[...Array(total)].map((_, index) => (
        <div
          key={index}
          className={`h-2 rounded-full flex-1 transition-colors space-x-2 ${
            index < current ? "bg-brand" : "bg-brand/20"
          }`}
        />
      ))}
    </div>
  );
};

interface GameCardProps {
  game: GameType;
  slackUser?: SlackUser;
}

const GameCard = ({ game, slackUser }: GameCardProps) => {
  const [data, setData] = useState<DataResponse | null>(null);
  const [loadingState, setLoadingState] = useState<LoadingStateType>(
    LoadingState.INITIAL,
  );
  const [error, setError] = useState<string>("");
  const [searchParams] = useSearchParams();
  const api = useApi();

  const playCount = data?.play_count ?? 0;
  const shouldShowInsights = playCount >= MINIMUM_PLAYS_FOR_INSIGHTS;
  const showProgress = playCount > 0 && playCount < MINIMUM_PLAYS_FOR_INSIGHTS;

  useEffect(() => {
    const fetchInsights = async () => {
      try {
        setLoadingState(LoadingState.LOADING);
        const response = await api.get(
          `${API_BASE_URL}/v2/insights/games/${game.name.toLowerCase()}`,
          { params: { team: searchParams.get("team") } },
        );
        setData(response.data);
        setLoadingState(LoadingState.SUCCESS);
      } catch (err) {
        console.error("Failed to fetch insights:", err);
        Sentry.captureException(err);
        setLoadingState(LoadingState.ERROR);

        if (err instanceof AxiosError) {
          setError(
            err.response?.status === 404
              ? "No insights found"
              : err.response?.status === 401
                ? "Please sign in to view insights"
                : err.response?.data?.message || "Failed to load insights",
          );
        } else {
          setError("Something went wrong");
        }
      }
    };

    fetchInsights();
  }, [game.name, api, searchParams]);

  const renderContent = () => {
    // Show loading spinner for initial load
    if (
      loadingState === LoadingState.INITIAL ||
      loadingState === LoadingState.LOADING
    ) {
      return (
        <div className="h-[180px] flex items-center justify-center">
          <LoadingSpinner />
        </div>
      );
    }

    if (loadingState === LoadingState.ERROR) {
      return (
        <div className="h-[180px] flex items-center justify-center text-sm text-brandRed">
          📈 {error}
        </div>
      );
    }

    // Only render actual content after we have data
    if (showProgress) {
      return (
        <div className="flex flex-col gap-3 bg-brand/10 p-4 rounded mt-4">
          <ProgressSteps
            total={MINIMUM_PLAYS_FOR_INSIGHTS}
            current={playCount}
          />
          <p className="text-sm text-brand">
            Play {MINIMUM_PLAYS_FOR_INSIGHTS - playCount} more{" "}
            {playCount === MINIMUM_PLAYS_FOR_INSIGHTS - 1 ? "game" : "games"} to
            unlock this insight
          </p>
        </div>
      );
    }

    if (!shouldShowInsights) {
      return (
        <div className="flex bg-brand/10 p-4 rounded mt-4">
          <p className="flex-grow text-sm text-brand whitespace-pre-line mr-4">
            {game.description}
          </p>
        </div>
      );
    }

    return data ? <GameInsight data={data} game={game} /> : null;
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 mb-4">
      <div className="flex flex-col">
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center">
            <img
              src={game.mini_image}
              alt={game.title}
              className="w-8 h-8 mr-2"
            />
            <h3 className="text-lg font-semibold">{game.title}</h3>
          </div>
          <div className="flex items-center space-x-4">
            <span className="text-xs text-gray-500">{playCount}x</span>
            <a
              href={generateSlackDeepLink(slackUser)}
              className="bg-gradient-cta text-white text-md px-4 py-2 rounded-[8px] hover:bg-gradient-cta-hover transition-colors whitespace-nowrap shadow-sm"
            >
              Play now
            </a>
          </div>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default GameCard;
