import { useSignIn } from "@clerk/clerk-react";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

import * as Sentry from "@sentry/react";

interface ClerkError {
  errors: Array<{
    code: string;
    message: string;
    longMessage: string;
    meta?: Record<string, unknown>;
  }>;
}

const SVGButton = () => (
  <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24" fill="currentColor">
    <path d="M5.042 15.165a2.528 2.528 0 0 1-2.52 2.523A2.528 2.528 0 0 1 0 15.165a2.527 2.527 0 0 1 2.522-2.52h2.52v2.52zM6.313 15.165a2.527 2.527 0 0 1 2.521-2.52 2.527 2.527 0 0 1 2.521 2.52v6.313A2.528 2.528 0 0 1 8.834 24a2.528 2.528 0 0 1-2.521-2.522v-6.313zM8.834 5.042a2.528 2.528 0 0 1-2.521-2.52A2.528 2.528 0 0 1 8.834 0a2.528 2.528 0 0 1 2.521 2.522v2.52H8.834zM8.834 6.313a2.528 2.528 0 0 1 2.521 2.521 2.528 2.528 0 0 1-2.521 2.521H2.522A2.528 2.528 0 0 1 0 8.834a2.528 2.528 0 0 1 2.522-2.521h6.312zM18.956 8.834a2.528 2.528 0 0 1 2.522-2.521A2.528 2.528 0 0 1 24 8.834a2.528 2.528 0 0 1-2.522 2.521h-2.522V8.834zM17.688 8.834a2.528 2.528 0 0 1-2.523 2.521 2.527 2.527 0 0 1-2.52-2.521V2.522A2.527 2.527 0 0 1 15.165 0a2.528 2.528 0 0 1 2.523 2.522v6.312zM15.165 18.956a2.528 2.528 0 0 1 2.523 2.522A2.528 2.528 0 0 1 15.165 24a2.527 2.527 0 0 1-2.52-2.522v-2.522h2.52zM15.165 17.688a2.527 2.527 0 0 1-2.52-2.523 2.526 2.526 0 0 1 2.52-2.52h6.313A2.527 2.527 0 0 1 24 15.165a2.528 2.528 0 0 1-2.522 2.523h-6.313z" />
  </svg>
);

const isClerkError = (error: unknown): error is ClerkError => {
  return (
    typeof error === "object" &&
    error !== null &&
    "errors" in error &&
    Array.isArray((error as ClerkError).errors)
  );
};

function SlackSignIn() {
  const { signIn, isLoaded } = useSignIn();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<string | null>(null);
  const redirectUrl = searchParams.get("redirect_url");

  if (!isLoaded) return null;

  const signInWithSlack = async () => {
    try {
      setError(null);
      await signIn.authenticateWithRedirect({
        strategy: "oauth_slack",
        redirectUrl: "/sso-callback",
        redirectUrlComplete: redirectUrl || "/profile",
      });
    } catch (err: unknown) {
      if (isClerkError(err)) {
        const errorMessage =
          err.errors[0]?.longMessage ?? "An error occurred during sign in";
        setError(errorMessage);
      } else {
        setError("An unexpected error occurred");
      }
      Sentry.captureException(err);
    }
  };

  return (
    <div className="flex flex-col items-center gap-3">
      <button
        onClick={signInWithSlack}
        className="bg-gradient-cta text-white px-4 py-3 rounded-lg hover:bg-gradient-cta-hover flex items-center justify-center shadow-md"
      >
        <SVGButton />
        Sign in with Slack
      </button>
      {error && (
        <p className="text-brandRed text-sm bg-red-50 p-2 rounded mt-4">
          {error}
        </p>
      )}
    </div>
  );
}

export default SlackSignIn;
