import { useAuth } from "@clerk/clerk-react";
import { useMemo } from "react";
import { createApi } from "../api";

export const useApi = () => {
  const { getToken } = useAuth();

  const api = useMemo(
    () =>
      createApi(() =>
        getToken({
          template: "default",
        }),
      ),
    [getToken],
  );

  return api;
};
