import { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { ArrowLeft, CreditCard, Shield } from "lucide-react";
import * as Sentry from "@sentry/react";

import { PromoData } from "../../types/promo";

import { calculatePrice } from "../../lib/utils";

import CTAButton from "../ctaButton";

interface CheckoutFormProps {
  onBack: () => void;
  plan: "monthly" | "annual";
  seats: number;
  teamId: string | null;
  promoData: PromoData | null;
}

function CheckoutForm({
  plan,
  seats,
  onBack,
  teamId,
  promoData,
}: CheckoutFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);

  const price = calculatePrice(plan === "annual", seats, promoData);

  const formatPromoMessage = () => {
    if (!promoData?.discount) return null;

    const { percent_off, duration, duration_months } = promoData.discount;

    if (duration === "once") {
      return `${percent_off}% off first month`;
    } else if (duration === "forever") {
      return `${percent_off}% off forever`;
    } else if (duration === "repeating" && duration_months) {
      return `${percent_off}% off for ${duration_months} month${duration_months > 1 ? "s" : ""}`;
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setProcessing(true);
    setError(null);

    try {
      const returnUrl = new URL(`${window.location.origin}/settings`);
      if (teamId) {
        returnUrl.searchParams.append("team", teamId);
      }

      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: returnUrl.toString(),
        },
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      Sentry.captureException(error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="max-w-full mx-auto">
      <div className="flex items-center mb-6 px-6 pt-6">
        <button
          type="button"
          onClick={onBack}
          className="text-gray-600 hover:text-gray-800 flex items-center"
        >
          <ArrowLeft size={20} className="mr-2" />
          Back
        </button>
      </div>

      <div className="px-6 pb-6">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Left Column - Order Summary */}
          <div className="lg:w-5/12 space-y-6">
            <div>
              <h2 className="text-2xl font-bold mb-1">Order Summary</h2>
              <p className="text-gray-600">Complete your upgrade to Premium</p>
            </div>

            <div className="bg-gray-50 p-4 rounded-xl space-y-3">
              <div className="flex justify-between">
                <span className="text-gray-600">Plan</span>
                <span className="font-medium">
                  {plan === "monthly" ? "Monthly" : "Annual"}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Seats</span>
                <span className="font-medium">{seats}</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-600">Price per seat</span>
                <span className="font-medium">
                  ${plan === "monthly" ? "3" : "2"}/month
                </span>
              </div>

              {promoData && (
                <div className="flex justify-between text-brandGreen">
                  <span>Promo Applied</span>
                  <span>{formatPromoMessage()}</span>
                </div>
              )}

              {/* Total price */}
              <div className="border-t pt-2 flex justify-between font-medium">
                <span>Total</span>
                <div className="text-right">
                  {promoData?.discount?.duration === "once" ? (
                    <>
                      <div className="text-lg line-through text-gray-400">
                        ${price.toFixed(2)}
                        {plan === "monthly" ? "/mo" : "/year"}
                      </div>
                      <div className="text-lg text-brandGreen">
                        First month free
                      </div>
                      <div className="text-sm text-gray-500">
                        Then ${price.toFixed(2)}
                        {plan === "monthly" ? "/mo" : "/year"}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-lg">
                        ${price.toFixed(2)}
                        {plan === "monthly" ? "/mo" : "/year"}
                      </div>
                      {plan === "annual" && (
                        <div className="text-sm text-gray-500">
                          ${(price / 12).toFixed(2)}/month
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center text-sm text-gray-600">
              <Shield size={16} className="mr-2 text-gray-400" />
              Your payment is secure and encrypted
            </div>
          </div>

          {/* Right Column - Payment Form */}
          <div className="lg:w-7/12">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <CreditCard size={20} className="text-gray-400" />
                  <h3 className="font-medium">Payment Method</h3>
                </div>
                <PaymentElement className="!bg-gray-100 p-2 rounded" />
              </div>

              {error && (
                <div className="text-brandRed text-sm bg-red-50 p-3 rounded-lg">
                  {error}
                </div>
              )}

              <CTAButton
                type="submit"
                disabled={!stripe}
                isLoading={processing}
                loadingText="Processing..."
                fullWidth
                size="lg"
              >
                Confirm Purchase
              </CTAButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutForm;
