import { createContext, useContext, ReactNode } from "react";
import { useSearchParams } from "react-router-dom";

type TeamContext = {
  teamId: string | null;
};

const TeamContext = createContext<TeamContext>({ teamId: null });

export function TeamProvider({ children }: { children: ReactNode }) {
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get("team");

  return (
    <TeamContext.Provider value={{ teamId }}>{children}</TeamContext.Provider>
  );
}

export function useTeamContext() {
  return useContext(TeamContext);
}
