import { useState } from "react";
import { Check, Minus, Plus, Tag } from "lucide-react";
import { buildApiUrl } from "../../api";

import { PromoData } from "../../types/promo";

import CTAButton from "../ctaButton";

import { useApi } from "../../hooks/useApi";

import * as Sentry from "@sentry/react";

import { calculatePrice, formatPromoMessage } from "../../lib/utils";

interface PlanSelectorProps {
  onSelect: (
    plan: "monthly" | "annual",
    seats: number,
    promoData: PromoData | null,
  ) => void;
  currentSeats?: number;
  minSeats?: number;
  promoCode?: string;
  isLoading?: boolean;
  teamId: string | null;
}

const PlanSelector = ({
  onSelect,
  currentSeats = 10,
  minSeats = 1,
  promoCode,
  isLoading = false,
  teamId,
}: PlanSelectorProps) => {
  const [seats, setSeats] = useState(Math.max(currentSeats, minSeats));
  const [selectedPlan, setSelectedPlan] = useState<"monthly" | "annual">(
    "monthly",
  );
  const [promoInput, setPromoInput] = useState(promoCode || "");
  const [isApplyingPromo, setIsApplyingPromo] = useState(false);
  const [promoError, setPromoError] = useState("");
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [promoData, setPromoData] = useState<PromoData | null>(null);

  const api = useApi();

  const handlePromoSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!promoInput.trim()) return;

    setIsApplyingPromo(true);
    setPromoError("");

    try {
      const response = await api.post(
        buildApiUrl("/v2/stripe/validate-promo", teamId),
        { code: promoInput.trim() },
      );

      setPromoData(response.data);
    } catch (err) {
      setPromoError("Could not apply promo code");
      setPromoData(null);
      Sentry.captureException(err);
    } finally {
      setIsApplyingPromo(false);
    }
  };

  const currentPrice = calculatePrice(
    selectedPlan === "annual",
    seats,
    promoData,
  );

  return (
    <div className="p-6 max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold text-center mb-8">Choose your plan</h2>

      {/* Billing Toggle */}
      <div className="flex justify-center mb-8">
        <div className="bg-gray-100 p-1 rounded-full inline-flex">
          <button
            onClick={() => setSelectedPlan("monthly")}
            disabled={isLoading}
            className={`px-6 py-2 rounded-full transition-all ${
              selectedPlan === "monthly"
                ? "bg-white shadow-sm text-brand font-medium"
                : "text-gray-600"
            } disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            Monthly
          </button>
          <button
            onClick={() => setSelectedPlan("annual")}
            disabled={isLoading}
            className={`px-6 py-2 rounded-full transition-all ${
              selectedPlan === "annual"
                ? "bg-white shadow-sm text-brand font-medium"
                : "text-gray-600"
            } disabled:opacity-50 disabled:cursor-not-allowed`}
          >
            Annual
            <span className="ml-1 text-xs text-brandGreen">Save 33%</span>
          </button>
        </div>
      </div>

      {/* Seat Selection */}
      <div className="bg-gray-50 p-6 rounded-xl mb-6">
        <div className="flex justify-between items-center mb-4">
          <div>
            <h3 className="font-medium">Team Size</h3>
            <p className="text-sm text-gray-500">
              How many people on your team?
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setSeats(Math.max(seats - 1, minSeats))}
              disabled={isLoading}
              className="p-2 rounded-full hover:bg-gray-200 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Minus size={18} />
            </button>
            <span className="text-xl font-semibold w-12 text-center">
              {seats}
            </span>
            <button
              onClick={() => setSeats(seats + 1)}
              disabled={isLoading}
              className="p-2 rounded-full hover:bg-gray-200 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Plus size={18} />
            </button>
          </div>
        </div>

        <div className="text-sm text-gray-600">
          ${currentPrice.toFixed(2)}/
          {selectedPlan === "annual" ? "year" : "month"} total
          <span className="mx-2">•</span>$
          {selectedPlan === "annual" ? "2" : "3"}/seat/month
        </div>
      </div>

      {/* Promo Code */}
      {!promoData && !showPromoInput ? (
        <button
          onClick={() => setShowPromoInput(true)}
          disabled={isLoading}
          className="flex items-center text-brand text-sm mb-6 hover:underline disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <Tag size={16} className="mr-2" />
          Have a promo code?
        </button>
      ) : (
        <form onSubmit={handlePromoSubmit} className="mb-6">
          <div className="flex space-x-2">
            <input
              type="text"
              value={promoInput}
              onChange={(e) => setPromoInput(e.target.value)}
              disabled={isLoading || isApplyingPromo || !!promoData}
              className="flex-1 px-4 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-brand/20 disabled:opacity-50 disabled:cursor-not-allowed"
              placeholder="Enter promo code"
            />
            {promoData ? (
              <CTAButton
                type="button"
                variant="secondary"
                size="sm"
                onClick={() => {
                  setPromoData(null);
                  setPromoInput("");
                }}
              >
                Remove
              </CTAButton>
            ) : (
              <CTAButton
                type="submit"
                variant="secondary"
                size="sm"
                isLoading={isApplyingPromo}
                loadingText="Applying..."
                disabled={!promoInput.trim()}
              >
                Apply
              </CTAButton>
            )}
          </div>
          {promoError && (
            <div className="text-brandRed text-sm mt-2">{promoError}</div>
          )}
          {promoData && (
            <div className="text-brandGreen text-sm mt-2">
              {formatPromoMessage(promoData)}
            </div>
          )}
        </form>
      )}

      <CTAButton
        onClick={() => onSelect(selectedPlan, seats, promoData)}
        isLoading={isLoading}
        loadingText="Setting up payment..."
        size="lg"
        fullWidth
      >
        <div className="space-y-1">
          <div>Continue to Payment</div>
          <div className="text-sm opacity-90">
            ${currentPrice.toFixed(2)}/
            {selectedPlan === "annual" ? "year" : "month"}
          </div>
        </div>
      </CTAButton>

      {/* Features List */}
      <div className="mt-6 space-y-3">
        <div className="text-sm text-gray-500 font-medium">
          Included in premium:
        </div>
        {[
          "Access to two new games a month",
          "Schedule recurring events",
          "Advanced insights and leaderboards",
          "Priority support",
        ].map((feature) => (
          <div
            key={feature}
            className="flex items-center text-sm text-gray-600"
          >
            <Check size={16} className="mr-2 text-brandGreen" />
            {feature}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlanSelector;
