import SlackSignIn from "../components/auth/signin";
import BgImage from "../assets/bg.jpg";

import Card from "../components/card";
import Logo from "../assets/braid-teams-logo.png";

const HeroText = () => (
  <div className="text-slate-800 max-w-xl text-center">
    <p className="text-md  opacity-90">
      Continue with Slack see your team's insights!
    </p>
  </div>
);

function LandingPage() {
  return (
    <div className="relative min-h-screen flex items-center justify-center">
      {/* Background */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${BgImage})` }}
      />

      {/* Content */}
      <div className="relative z-10 flex px-4 text-center text-slate-900">
        <Card>
          <div className="flex flex-col items-center justify-center w-[400px]">
            <img src={Logo} alt="Braid Teams" className="h-16 w-auto mb-8" />
            <HeroText />
            <div className="bg-white/10 backdrop-blur-sm p-8 rounded-lg">
              <SlackSignIn />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default LandingPage;
