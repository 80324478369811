import React from "react";
import { useUserProfile } from "../hooks/useUserProfile";
import Spinner from "../components/spinner";
import Card from "../components/card";
import GameCard from "../components/gameCard";
import UpgradeSection from "../components/upgradePrompt";
import playedTeammatesIcon from "../assets/icons/playedTeammatesIcon.png";
import playedGamesIcon from "../assets/icons/playedGamesIcon.png";
import { UserData, SlackUser } from "../types/user";
import { GameType } from "../types/games";

interface AvailableGamesProps {
  games: GameType[];
  slackUser?: SlackUser;
  isPremium: boolean;
}

const AvailableGames: React.FC<AvailableGamesProps> = ({
  games,
  slackUser,
  isPremium,
}) => {
  if (!games.length) {
    return null;
  }

  // If premium, show all games
  if (isPremium) {
    return (
      <div className="mt-8">
        {games.map((game) => (
          <GameCard key={game.id} game={game} slackUser={slackUser} />
        ))}
      </div>
    );
  }

  // If not premium, show first game followed by upgrade section
  return (
    <div className="mt-8">
      {/* Show first game */}
      <GameCard game={games[0]} slackUser={slackUser} />

      {/* Show upgrade section if there are more games */}
      {games.length > 1 && <UpgradeSection />}
    </div>
  );
};

function Profile() {
  const {
    userData,
    gamesPlayed,
    teammatesPlayed,
    latestGameIcons,
    teammateIconsSliced,
    isLoading,
    isError,
    error,
  } = useUserProfile();

  const isPremium =
    userData?.slack_user?.subscription?.tier?.tier_type === "Premium";

  if (isLoading) {
    return (
      <div className="h-full flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="font-semibold text-brandRed">
          Error: {error instanceof Error ? error.message : "An error occurred"}
        </div>
      </div>
    );
  }

  if (!userData) {
    return <div className="text-center mb-4">No user data available.</div>;
  }

  const availableGames =
    userData.slack_user?.subscription.available_games || [];

  return (
    <div className="h-full">
      <Card>
        <div className="flex items-center justify-between">
          <UserInfo userData={userData} />
          <UserStats
            gamesPlayed={gamesPlayed.length}
            teammatesPlayed={teammatesPlayed.length}
            latestGameIcons={latestGameIcons}
            latestTeammatesIcons={teammateIconsSliced}
          />
        </div>
      </Card>
      <AvailableGames
        games={availableGames}
        slackUser={userData.slack_user}
        isPremium={isPremium}
      />
    </div>
  );
}

interface UserInfoProps {
  userData: UserData;
}

const UserInfo: React.FC<UserInfoProps> = ({ userData }) => (
  <div className="flex items-center">
    <img
      className="h-16 w-16 rounded-full mr-4"
      src={userData.avatar_url}
      alt={userData.name || "User avatar"}
    />
    <div>
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {userData.name}
      </h3>
      <div className="text-[12px] text-gray-500">{userData.email}</div>
      <div className="text-[12px] text-gray-500">
        Team &mdash; {userData.slack_user?.installation?.team_name}
      </div>
    </div>
  </div>
);

interface UserStatsProps {
  gamesPlayed: number;
  teammatesPlayed: number;
  latestGameIcons: string[];
  latestTeammatesIcons: string[];
}

const UserStats: React.FC<UserStatsProps> = ({
  gamesPlayed,
  teammatesPlayed,
  latestGameIcons,
  latestTeammatesIcons,
}) => (
  <div className="flex items-center space-x-2 md:space-x-6">
    <StatItem
      icons={latestGameIcons}
      label="games played"
      value={gamesPlayed}
      defaultIcon={playedGamesIcon}
    />
    <StatItem
      defaultIcon={playedTeammatesIcon}
      label="teammates"
      icons={latestTeammatesIcons}
      value={teammatesPlayed}
      isAvatar
    />
  </div>
);

interface StatItemProps {
  defaultIcon: string;
  icons?: string[];
  label: string;
  value: number;
  isAvatar?: boolean;
}

const StatItem: React.FC<StatItemProps> = ({
  defaultIcon,
  icons,
  label,
  value,
  isAvatar,
}) => {
  return (
    <div className="flex flex-col items-center overflow-hidden">
      <div className="flex items-center -space-x-3">
        {icons && icons.length > 0 ? (
          icons.map((iconSrc, index) => (
            <div
              key={index}
              className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center border-2 border-white "
            >
              <img
                src={iconSrc}
                className={isAvatar ? "rounded-full" : "w-5 h-5"}
              />
            </div>
          ))
        ) : (
          <img src={defaultIcon} className="w-8 h-8" />
        )}
      </div>
      <p className="text-xs md:text-sm font-semibold text-gray-900 mt-2">
        {value > 1000 ? "999+" : value}
      </p>
      <p className="text-xs md:text-sm text-gray-500 hidden md:block">
        {label}
      </p>
    </div>
  );
};

export default Profile;
