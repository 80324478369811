import Header from "./header";

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  return (
    <div className="min-h-screen">
      <Header />
      <main className="py-10 container mx-auto px-2 lg:px-0 md:px-0">
        <div className="max-w-5xl mx-auto">{children}</div>
      </main>
    </div>
  );
}

export default Layout;
