import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from "recharts";
import { type GradientLineChartData } from "../../../types/games";
import React from "react";

type GradientLineChartProps = {
  data: GradientLineChartData;
};

const GradientLineChart: React.FC<GradientLineChartProps> = ({ data }) => {
  const { type: dataType, values: chartValues } = data;
  const datasetValues = chartValues.map((dataset) => dataset.value);
  const min = Math.min(...datasetValues);
  const max = Math.max(...datasetValues);

  const diff = max - min;
  const step = Math.ceil(diff / 4 / 5) * 5;

  const minTick =
    min % step < 3 ? min - (min % step) : min + (step - (min % step));

  const maxTick =
    max % step >= 3 ? max + (step - (max % step)) : max - (max % step);

  const ticks = new Array((maxTick - minTick) / step + 1)
    .fill(1)
    .map((_, index) => minTick + index * step);

  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart
        width={300}
        height={200}
        data={chartValues}
        margin={{
          top: 5,
          right: 30,
          left: -15,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="value"
          tickLine={false}
          axisLine={false}
          fill="#808080"
          fontSize={12}
          fontFamily="Inter"
          ticks={[
            chartValues[0].value,
            chartValues[chartValues.length - 1].value,
          ]}
          tickFormatter={(_, index) =>
            index === 0 ? "First Game" : "Last Game"
          }
        />
        <YAxis
          type="number"
          tickLine={false}
          axisLine={false}
          allowDataOverflow
          padding={{ bottom: 30, top: 20 }}
          domain={[minTick, maxTick]}
          ticks={ticks}
          tick={({ y, payload }) => (
            <g transform={`translate(${0},${y + 2})`}>
              <text
                x={0}
                y={2}
                fill="#808080"
                textAnchor="start"
                fontSize={12}
                fontFamily="Inter"
              >
                {payload.value}
                {dataType === "percent" && "%"}
              </text>
            </g>
          )}
        />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#7367FF" />
            <stop offset="100%" stopColor="#4641EB" />
          </linearGradient>
        </defs>
        <Line
          type="monotone"
          dataKey="value"
          stroke="url(#colorUv)"
          strokeWidth={4}
          strokeLinecap="round"
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default GradientLineChart;
