import React from "react";

type WidgetCardProps = React.PropsWithChildren & {
  title?: string;
  subtitle?: string;
  halfWidth?: boolean;
};

export const WidgetCard: React.FC<WidgetCardProps> = ({
  title,
  subtitle,
  children,
  halfWidth = false,
}) => {
  const gridClass = halfWidth ? "col-span-1" : "col-span-1 md:col-span-2";
  return (
    <div
      className={`bg-white w-full p-5 rounded-xl border-2 border-black/5 ${gridClass}`}
    >
      {title && (
        <h3 className="font-semibold font-sans text-md mb-2">{title}</h3>
      )}

      {subtitle && (
        <div className="font-sans text-xs text-black/50">{subtitle}</div>
      )}

      <div className="mt-5">{children}</div>
    </div>
  );
};
