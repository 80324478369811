import { useQuery } from "@tanstack/react-query";
import { useUser } from "@clerk/clerk-react";
import { useApi } from "./useApi";
import { UserData, UserDataResponse } from "../types/user";
import { useTeamContext } from "../contexts/TeamContext";

export function useUserData() {
  const { user } = useUser();
  const { teamId } = useTeamContext();
  const api = useApi();

  const fetchUserData = async (): Promise<UserData> => {
    const params = new URLSearchParams();
    if (teamId) {
      params.append("team", teamId);
    }

    const { data } = await api.get<UserDataResponse>(
      `/v2/users/me?${params.toString()}`,
    );

    const { first_name, last_name, avatar_url, email } = data.metadata;
    const name = `${first_name} ${last_name || ""}`.trim();

    return {
      uid: data.uid,
      name,
      avatar_url,
      email,
      slack_user: data.slack_user,
    };
  };

  return useQuery({
    queryKey: ["userData", user?.id, teamId],
    queryFn: fetchUserData,
    enabled: !!user,
  });
}
